<template>
<vx-card no-shadow >
    <h4 class="mb-4">Order Details</h4>
    <div class="mb-4">
        <img width="100px" :src="absoluteUrl(cart.product.image)" />
        <h5 class="mt-3"><a :href="absoluteUrl(`/${cart.product.slug}`)" class="cursor-pointer" target="_blank">{{cart.product.name}}</a></h5>
    </div>
    <ul class="summary-content">
        <li class="flex mb-2 justify-between">Status<strong class="" v-html="$store.getters['orderStatus'](order.status)"></strong></li>
        <li class="flex mb-2 justify-between">Delivery date<strong class="">{{$store.getters['deliveryDate'](order.created_at, order.delivery_time)}}</strong></li>
        <li class="flex mb-2 justify-between">Total price<strong class=" price">{{order.currency_symbol}}{{order.amount | money_format}} <span class="uppercase">{{order.currency_code}}</span> </strong></li>
        <li class="flex justify-between">Order number<strong class="">#{{order.number}}</strong></li>
    </ul>
</vx-card>
</template>

<script>
export default {
    computed: {
        order(){
            return this.$store.state.orderList.order
        },
        cart(){
            return this.order.cart
        },
    }
}
</script>