

<template>
    <div>
        <h6> Package: <span class="text-success">{{orderPackage.name}} - {{order.currency_symbol}}{{orderPackage.price}}</span></h6>
        <ul class="list">
            <li class="list__item" ><feather-icon icon="CheckIcon" :svgClasses="'icon-config'" class="w-4 text-success h-4 mr-1"></feather-icon> <span > {{orderPackage.revisions}} Revision</span></li>
            <template  v-for="(attribute, index) in attributes">
                <li class="list__item" :key="index" v-if="supports[attribute.id] && supports[attribute.id] != 'not-supported'">
                    <feather-icon icon="CheckIcon" :svgClasses="'icon-config'" class="w-4 text-success h-4 mr-1"></feather-icon>
                    <span class="text-sm">
                    <span v-if="attribute.type == 'input'"> {{supports[attribute.id]}} </span>
                    <span> {{attribute.name | truncate(30)}}</span>
                    </span>
                </li>
            </template>

            <li class="list__item" v-if="addons.length" > <span class="font-bold">Addons:</span></li>
            <li class="list__item" v-for="(addon,  index) in addons" :key="`pxd_${index}`">
                <feather-icon icon="CheckIcon" :svgClasses="'icon-config'" class="w-4 text-success h-4 mr-1"></feather-icon>
                <span class="text-sm">
                <span>{{addon.name | truncate(30)}} => {{order.currency_symbol}}{{addon.charge}}</span>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "order-feature-list",
    computed: {
        order(){
            return this.$store.state.orderList.order
        },
        cart(){
            return this.order.cart
        },
        orderPackage(){
            return this.cart.package
        },
        attributes(){
            return this.cart.attributes
        },
        addons(){
            return this.cart.addons
        },
        supports(){
            return this.orderPackage.attributes
        },
    }
}
</script>
